.alert-container {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 300px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideIn 0.3s ease-out;
    z-index: 1000;
  }
  
  .alert-content {
    flex-grow: 1;
  }
  
  .alert-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .alert-description {
    margin: 4px 0 0 0;
    font-size: 14px;
  }
  
  .alert-close-icon {
    cursor: pointer;
    margin-left: 16px;
  }
  
  .alert-container.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .alert-container.error {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .alert-container.warning {
    background-color: #fff3cd;
    color: #856404;
  }
  
  .alert-container.info {
    background-color: #d1ecf1;
    color: #0c5460;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  