.progress-bar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .progress-bar-background {
    width: 100%;
    background-color: #6E6E6E;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 20px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #FC5130;
    transition: width 0.3s ease;
  }
  
  .progress-bar-text {
    margin-top: 8px;
    font-size: 14px;
    color: #333;
  }
  