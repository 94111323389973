.organizacionContenedor01{
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin:20px;
}

.firstColumn01{
    width: 250px;
}

.table01{
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 20px;
    color: #656565;
}

.table01 td{
    padding: 5px;
    font-size: 14px;
}

.button01{
    background-color: white;
    color: #656565;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
}
.button01:hover{
    background-color: #D9D9D9;
}



.secondColumn01{
    width: 80%;
}

.secondColumn01 h3{
    font-size: 14px;
    margin-bottom: 10px;
}