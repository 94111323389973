.subMenuContainer{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 10vh;
}

.subMenuContainerButton{
    padding: 7px 15px;
    background-color: #F65232;
    color: white;
    border: none;
    cursor: pointer;
}

.subMenuList ul{
    margin-top: 10px;
    display:flex;
    list-style: none;

}

.subMenuList ul li{
    padding: 10px;
    cursor: pointer;
}

.subMenuList  .active{
    border-bottom: 3px solid #F65232;
}

