.PlanBanner_Container {
  background-color: #f65232;
  color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  margin: 0;
}

.PlanBanner_Container h3 {
  font-size: 1.2em;
  margin: 0;
  font-weight: 500;
}

.PlanBanner_Container button {
  background-color: #f65232;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  font-size: 1em;
  padding: 10px 20px;
}
