.FieldsModalStyles_Container {
  width: 100%;
  padding: 0px;
  background-color: #f1f1f1;
  position: absolute;
  top: 50;
  left: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.FieldsModalStyles_List {
  text-align: left;
  padding: 10px 0px;
}

.FieldsModalStyles_List li {
  list-style: none;
  padding: 10px 10px;
}
.FieldsModalStyles_List li:hover {
  background-color: rgb(14, 78, 255);
  color: white;
  cursor: pointer;
}

.FieldsModalStyles_List li:last-child {
  color: rgb(14, 78, 255);
}
.FieldsModalStyles_List li:last-child:hover {
  cursor: pointer;
}
