*{
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
}


.table{
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
}

.table thead tr{
    background-color: #D9D9D9;
    color: #707070;
    text-align: left;
}

.table th, .table td{
    padding: 12px 15px;
}

.table th{
    font-weight: 500;
}

.table tbody{
    background-color: white;

}

.global-search-input{
    border: 1px solid #BDBDBD;
    padding: 7px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 20px 0px;
    background-color: white;
}



.global-search-input::placeholder{
    color: grey;
}

.global-search-input input{
    border: none;
    padding: 5px;
    width: 100%;
    margin-left: 5px;
    background-color: white;
}

.global-search-input input:focus{
    outline: none;
}

.global-search-input svg{
    color:#707070;
    margin-left: 10px;
}

.global-action-button{
    background-color: #F65232;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0px;
    min-width: 180px;
}

.global-action-button:hover{
    background-color: #B03D26;
    color: white;
    transition: ease all 0.3s;
}

.global-action-delete-button{
    background-color: none;
    color: #F65232;
    padding: 10px 20px;
    border: 1px solid #F65232;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0px;
    min-width: 180px;
}

.global-action-delete-button:hover{
    background-color: #B03D26;
    color: white;
    transition: ease all 0.3s;
}

.global-fila{
    display: flex;
    gap: 20px;
    margin: 20px;
    
}

.global-fila > div:first-child{
    width: 80%;
    background-color: white;
    padding: 20px;
}
.global-fila div:last-child{
    width: 20%;
    background-color: white;
    padding: 20px;
}

.global-icon-row{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.global-icon-row h2{
    font-weight: 500;
    color: #001E38;
}

.global-icon-row svg{
    margin-right: 10px;
    color: #B1B1B1;
    font-size: 22px;
}

.global-fila div:last-child p{
    margin:10px 0px;
}

.global-fila div:last-child h3{
    font-weight: 500;
    color: #001E38;

}


/* MODAL */
.global-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.global-modal-content {
    background-color: #F1F1F1;
    padding: 20px;
    border-radius: 8px;
    width: 60%;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.global-modal-body {
    margin: 20px 0;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    max-height: 100%;
}

.global-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.global-modal-header svg{
    cursor: pointer;
    font-size: 20px;

}

.global-modal-title {
    margin: 0;
    font-size: 1.25em;
    text-align: center;
}

.global-close-icon {
    cursor: pointer;
}

.global-input-group {
    margin-bottom: 15px;
}

.global-modal-footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
}