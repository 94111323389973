.SeguridadComponent_container {
    background-color: white;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
  }
  
  .SeguridadComponent_form {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
  }
  
  .SeguridadComponent_form .form-group {
    margin-bottom: 20px;
  }
  
  .SeguridadComponent_form label {
    display: block;
    margin-bottom: 5px; /* Espacio entre el label y el input */
  }
  
  .SeguridadComponent_form input {
    width: 40%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* Asegura que el padding y el border se incluyan en el ancho total */
  }
  
  .SeguridadComponent_form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #F65232;
    color: white;
    cursor: pointer;
  }
  
  .SeguridadComponent_form button:hover {
    background-color: #b83c23;
  }
  