.AutocompletarModal_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.AutocompletarModal_box {
  background-color: white;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  position: relative;
}

.AutocompletarModal_box .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.AutocompletarModal_box h3{
    text-align: center;
    margin-bottom: 20px;
}

.AutocompletarModal_box button{
    background-color: #00559E;
    border: none;
    color:white;
    width: 100%;
    margin: 5px 0px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 17px;
}