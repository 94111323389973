.loading_overlay {
  position: fixed; /* Cambia a fixed para que se mantenga en pantalla completa */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  opacity: 0;
  animation: loading_fadeIn 0.3s forwards;
}

.loading_fadeOut {
  animation: loading_fadeOut 0.3s forwards;
}

@keyframes loading_fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes loading_fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.loading_content {
  text-align: center;
  color: white;
}

.loading_icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  animation: loading_rotate 1s linear infinite;
}

@keyframes loading_rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading_text {
  margin-bottom: 10px;
  font-size: 18px;
}

.loading_dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  margin: 0 4px;
  opacity: 0;
  animation: loading_blink 1.4s infinite both;
}

.loading_dot:nth-child(1) {
  animation-delay: 0.2s;
}

.loading_dot:nth-child(2) {
  animation-delay: 0.4s;
}

.loading_dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading_blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
