.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  
  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  
  .button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #e84a3e;
    color: white;
    width: 100%;
  }
  
  .delete-button {
    background-color: white;
    color: #e84a3e;
    border: 1px solid #e84a3e;
    width: 100%;
  }
  
    .button:hover {
        filter: brightness(0.9);
    }